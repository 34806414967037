import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { Link } from "gatsby";

const Load = keyframes`
 from, 0%, to {
  background-position: 100% 0;
}
100% {
  background-position: -100% 0;
  }`;

export const Skeleton = styled.div`
  height: 200px;
  width: 90%;
  background-color: #e5e5e5;
  border-radius: 4px;
`;

export const LoadCard = styled.div`
  height: 300px;
  width: 100%;
  margin: 1em 1em;
  .span-2 {
    grid-column: auto / span 3;
    position: relative;
    display: flex;
    align-items: center;
  }
  .group {
    width: 100%;
    padding: 2em;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  & h1 {
    background-color: #e5e5e5;
    width: 40%;
    height: 10px;
    border-radius: 2px;
  }
  & p {
    background-color: #e5e5e5;
    width: 80%;
    height: 20px;
    border-radius: 2px;
  }
  & h1,
  p,
  ${Skeleton} {
    background: linear-gradient(
      120deg,
      #e5e5e5 30%,
      #f0f0f0 38%,
      #f0f0f0 40%,
      #e5e5e5 48%
    );
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: ${Load} 2s infinite;
  }
`;

export const SeeMoreButton = styled.button`
  border: 0px;
  background-color: transparent;
  padding: 0.5em 2em;
  font-size: 1rem;
  color: #fbb400;
  cursor: pointer;
  text-transform: capitalize;
  :hover {
    text-decoration: underline;
  }
`;

export const StyledLink = styled(Link)`
  margin: 1rem 0rem;
  font-size: clamp(1rem, 2.5vw, 1rem);
  font-weight: 700;
  color: #000;
  font-style: normal;
  text-decoration: none;
  :hover {
    /* color: #5a5a5a; */
    color: #fbb400;
  }
`;
export const ImageContainer = styled.div`
  margin: 0 auto;
  cursor: pointer;
  .block-img {
    margin-bottom: 2rem;
    [data-src] {
      opacity: 0;
      transition: opacity 0.7s ease-in;
    }
  }
`;

export const CardWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  /* grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr)); */
  margin-bottom: 2em;
  grid-gap: 1em;
  @media (min-width: 760px) {
    .span-2 {
      /* grid-column: auto / span 3; */
      grid-column: span 3;
      position: relative;
      display: flex;
      align-items: center;
      ${ImageContainer} {
        width: calc((100% - 264px) / 12 * 10 + 192px);
        margin: unset;
        .block-img {
          margin-bottom: 0rem;
        }
      }
    }
    .wrapper-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc((100% - 200px) / 12 * 4 + 72px);
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid rgba(64, 87, 109, 0.07);
      padding: 0em 1em;
      max-height: 260px;
      height: 90%;
      transform: translate(-20%, 0%);
      ${StyledLink} {
        margin: 0.5rem 0rem;
        font-size: clamp(1rem, 2.5vw, 1.5rem);
      }
      .wrapper-category {
        font-size: clamp(1rem, 2.5vw, 1.1rem);
      }
    }
  }
`;

// ---------------------- container blog -------------------------

export const WrapperShare = styled.div`
  .button {
    margin-right: 0.5em;
  }
  .icon {
    color: #aaa !important;
    :hover {
      color: #000 !important;
    }
  }
`;
